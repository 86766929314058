<template>
    <div class="Category-container">
        <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <vab-query-form>
                    <vab-query-form-top-panel :span="12">
                        <el-button icon="el-icon-plus" type="primary" @click="handleAdd(null,'Root')">
                            添加分类
                        </el-button>
                    </vab-query-form-top-panel>
                </vab-query-form>
                <el-table :data="list"
                          border
                          row-key="id"
                          default-expand-all
                          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
                    <!--基本信息-->
                    <el-table-column show-overflow-tooltip
                                     width="200"
                                     prop="name"
                                     label="商品分类"></el-table-column>

                    <el-table-column show-overflow-tooltip width="100" label="是否禁用" align="center">
                        <template #default="{ row }">
                            <span>
                                {{ row.isDisable ? '是' : '否' }}
                            </span>
                        </template>
                    </el-table-column>

                    <el-table-column show-overflow-tooltip
                                     width="80"
                                     prop="id"
                                     label="商品ID"></el-table-column>



                    <el-table-column show-overflow-tooltip label="操作" min-width="250">
                        <template #default="{ row }">
                            <el-button type="primary" @click="handleAdd(row.id,row.name)" icon="el-icon-plus">添加分类</el-button>
                            <el-button type="info" @click="handleEdit(row)" icon="el-icon-edit-outline">编辑分类</el-button>

                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>

        <edit ref="edit" @fetch-data="fetchData"></edit>
        <CategorySpec ref="categorySpec" @fetch-data="fetchData"></CategorySpec>
    </div>
</template>

<script>
    import { formatToTree } from '@/utils';
    import { GetCategories } from '@/api/position';
    import Edit from './components/CategoryEdit';
    

    export default {
        name: 'Category',
        components: { Edit },
        data() {
            return {
                defaultProps: {
                    children: 'children',
                    label: 'label',
                },
                list: []
            }
        },
        async created() {
            this.fetchData();
        },
        methods: {
            handleAdd(parentId, parentName) {
                this.$refs['edit'].showAdd(parentId, parentName);
            },
            handleEdit(row) {
                this.$refs['edit'].showEdit(row);
            },
            async fetchData() {
                const { isSucceed, message, result } = await GetCategories();
                if (!isSucceed) return this.$baseMessage(message, 'error');
                let tree = formatToTree(result);
                //console.log('[tree]' + JSON.stringify(tree));
                this.list = tree;
            },
            handleNodeClick() {
                this.fetchData();
            },
            handleEditSpec(row) {
                this.$refs['categorySpec'].showSpecEdit(row);
            } 
        },
    }
</script>
